// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import withUserAuth from "./components/hoc/withUserAuth";
import UserRegister from "./pages/user/UserRegister";
import UserLogin from "./pages/user/UserLogin";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import UserDashboard from "./pages/user/UserDashboard";
import UserProfile from "./pages/user/UserProfile";
import Membership from "./pages/user/Membership";
import UserMembership from "./pages/user/UserMembership";
import CancelMembership from "./pages/user/CancelMembership";
import PaymentHistory from "./pages/user/PaymentHistory";
import ProjectInsights from "./pages/user/ProjectInsights";
import Quiz from "./pages/user/Quiz";
import PaypalInvoice from "./pages/user/PaypalInvoice";
import NotFoundUser from "./pages/user/NotFoundUser";

import withAdminAuth from "./components/hoc/withAdminAuth";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminProfile from "./pages/admin/AdminProfile";
import AdminCarbonPriceUpdate from "./pages/admin/AdminCarbonPriceUpdate";
import AdminProjectUpload from "./pages/admin/AdminProjectUpload";
// import AdminProjectList from "./components/admin/AdminProjectList";
import AdminProjectList from "./pages/admin/AdminProjectList";
import AdminCurrentMembers from "./pages/admin/AdminCurrentMembers";
import AdminCanceledMembers from "./pages/admin/AdminCanceledMembers";
import NotFoundAdmin from "./pages/admin/NotFoundAdmin";

const ProtectedUserDashboard = withUserAuth(UserDashboard);
const ProtectedUserProfile = withUserAuth(UserProfile);
const ProtectedProjectInsights = withUserAuth(ProjectInsights);
const ProtectedMembership = withUserAuth(Membership);
const ProtectedUserMembership = withUserAuth(UserMembership);
const ProtectedCancelMembership = withUserAuth(CancelMembership);
const ProtectedPaymentHistory = withUserAuth(PaymentHistory);
const ProtectedQuiz = withUserAuth(Quiz);

const ProtectedAdminProfile = withAdminAuth(AdminProfile);
const ProtectedAdminCarbonPriceUpdate = withAdminAuth(AdminCarbonPriceUpdate);
const ProtectedAdminProjectUpload = withAdminAuth(AdminProjectUpload);
const ProtectedAdminProjectList = withAdminAuth(AdminProjectList);
const ProtectedAdminCurrentMembers = withAdminAuth(AdminCurrentMembers);
const ProtectedAdminCanceledMembers = withAdminAuth(AdminCanceledMembers);

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route for user registration */}
        <Route path="/" element={<UserRegister />} />
        <Route path="/register" element={<UserRegister />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/dashboard" element={<ProtectedUserDashboard />} />
        <Route path="/take-quiz" element={<ProtectedQuiz />} />
        <Route path="/profile" element={<ProtectedUserProfile />} />
        <Route path="/membership" element={<ProtectedMembership />} />
        <Route path="/user-membership" element={<ProtectedUserMembership />} />
        <Route
          path="/cancel-membership"
          element={<ProtectedCancelMembership />}
        />
        <Route path="/payment-history" element={<ProtectedPaymentHistory />} />
        <Route path="/paypal-invoice/:invoiceId" element={<PaypalInvoice />} />
        <Route path="/projects" element={<ProtectedProjectInsights />} />

        {/* Define the routes for admin */}
        <Route path="/admin/" element={<AdminLogin />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/profile" element={<ProtectedAdminProfile />} />
        <Route
          path="/admin/carbon-price"
          element={<ProtectedAdminCarbonPriceUpdate />}
        />
        <Route
          path="/admin/project-upload"
          element={<ProtectedAdminProjectUpload />}
        />
        <Route path="/admin/projects" element={<ProtectedAdminProjectList />} />
        <Route
          path="/admin/current-members"
          element={<ProtectedAdminCurrentMembers />}
        />
        <Route
          path="/admin/canceled-members"
          element={<ProtectedAdminCanceledMembers />}
        />
        <Route path="/admin/*" element={<NotFoundAdmin />} />
        <Route path="*" element={<NotFoundUser />} />
      </Routes>
    </Router>
  );
}

export default App;
