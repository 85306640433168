// src/components/user/UserRegister.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import PaypalPaymentForm from "./PaypalPaymentForm";
import Logo from '../../pictures/logo.png'
import Button from "../uiKit/Button";
import Loader from "../uiKit/Loader";
import Popup from "../uiKit/Popup";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UserRegister = () => {

  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    company: "",
    ABN: "",
    address: "",
    city: "",
    state: "",
    country: "",
    password: "",
    confirmPassword: "",
    membershipType: "type1",
    paymentMethod: "stripe",
    stripeSubscriptionId: "",
    paypalSubscriptionId: "",
  });
  const [isPaymentMode, setIsPaymentMode] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    company: '',
    ABN: '',
    address: '',
    city: '',
    state: '',
    country: '',
    password: '',
    confirmPassword: '',
  });
  

  useEffect(() => {
    if (formData.confirmPassword && formData.password !== formData.confirmPassword) {
      setErrors(prev => ({ ...prev, confirmPassword: 'Passwords do not match' }));
    } else {
      setErrors(prev => ({ ...prev, confirmPassword: '' }));
    }
  }, [formData.password, formData.confirmPassword]);
  

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const validateField = (name, value) => {
    let error = '';
    if (!value) {
      let fieldName = name === 'ABN' ? 'ABN' : name.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

      error = `${fieldName} is required`;
    } else if (name === 'email' && !/^\S+@\S+\.\S+$/.test(value)) {
      error = 'Invalid email address';
    } else if (name === 'password') {
      if (value.length < 8) {
        error = 'Password must be at least 8 characters long';
      } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(value)) {
        error = 'Password must contain at least one capital letter and one number';
      }
    } else if (name === 'confirmPassword' && value !== formData.password) {
      error = 'Passwords do not match';
    }
    setErrors(prev => ({ ...prev, [name]: error }));

  };  

  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);

    if (name === 'password') {
      validateField('confirmPassword', formData.confirmPassword);
    }
  };

  // const validateAllFields = () => {
  //   const fields = ['firstName', 'lastName', 'contactNumber', 'email', 'company', 'ABN', 'address', 'city', 'state', 'country', 'oldPassword', 'newPassword', 'confirmPassword'];
  //   const validationErrors = fields.map(field => validateField(field, formData[field] || ''));
  //   return !validationErrors.some(error => error !== '');
  // };


  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  
  //   // Ensure all fields are valid before proceeding
  //   if (!validateAllFields()) {
  //     console.error("Validation failed.");
  //     return; // Prevent form submission
  //   }
  
  //   // If validation passes, proceed with form submission logic
  //   // For example, handlePasswordSave() or other submission logic here
  // };


  const handleMakePayment = () => {
    setShowLoader(true);
    const validationErrors = Object.keys(formData).reduce((acc, key) => {
      const error = validateField(key, formData[key]);
      if (error) acc[key] = error;
      setShowLoader(false);
      return acc;
    }, {});
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowLoader(false);
      return;
    }
    setShowLoader(false);
    setIsPaymentMode(true);
  };

  const handleCancelPayment = () => {
    setIsPaymentMode(false);
  };

  const treesPlanted = (membershipType) => {
    switch (membershipType) {
      case "type1":
        return 1;
      case "type2":
        return 5;
      case "type3":
        return 10;
      default:
        return 0;
    }
  };

  const handlePaymentSuccess = async (subscriptionId, invoiceDetails) => {
    setShowLoader(true);
    // Check for a valid subscription ID
    if (!subscriptionId) {
      // alert("Failed to get the subscription ID. Please try again.");
      setShowLoader(false);
      setPopup({ show: true, message: "Failed to get the subscription ID. Please try again." });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      return;
    }

    // Prepare the registration data with the received subscriptionId
    const registrationData = {
      ...formData,
      stripeSubscriptionId:
        formData.paymentMethod === "stripe" ? subscriptionId : "",
      paypalSubscriptionId:
        formData.paymentMethod === "paypal" ? subscriptionId : "",
      invoiceDetails: invoiceDetails,
      treesPlanted: treesPlanted(formData.membershipType),
    };

    try {
      // Send user registration data to your backend
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(registrationData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setShowLoader(false);
        throw new Error(data.message || "User registration failed.");
      }

      localStorage.setItem("token", data.token);
      setShowLoader(false);
      navigate("/login"); 
    } catch (error) {
      setShowLoader(false);
      setPopup({ show: true, message: "Registration error:" + error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      // console.error("Registration error:", error);
      // alert(error.message);
    }
  };

  const handlePaymentError = (error) => {
    // console.error("Payment error:", error);
    // alert("Payment failed. Please try again.");
    setPopup({ show: true, message: "Payment error:", error });
    setTimeout(() => setPopup({ show: false, message: "" }), 2000);
  };

  const renderPaymentMethod = () => {
    if (formData.paymentMethod === "stripe") {
      return (
        <Elements stripe={stripePromise}>
          <StripePaymentForm
            formData={formData}
            onPaymentSuccess={handlePaymentSuccess}
            onPaymentError={handlePaymentError}
          />
        </Elements>
      );
    } else if (formData.paymentMethod === "paypal") {
      return (
        <PaypalPaymentForm
          formData={formData}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
        />
      );
    }
  };







  return (
    <div className="container-form">
      <div className="transparent-bg inline-block lg:px-[180px] pb-[25px] md:pb-[45px] lg:pb-[65px]">
        <div className="user-register">
          <div className="flex flex-col lg:flex-row pb-[10px] md:pb-[20px]">
            <div className="md:basis-2/5">
              <img src={Logo} alt='' className="m-auto site-logo"/>
            </div>
            <div className="md:basis-3/5 flex flex-col justify-end text-center lg:text-left">
              <h1 className="page-header">Start Fixing Your Planet</h1>
              <p className="pt-[8px]">Flexiable Memberships. Cancel Anytime.</p>
            </div>
          </div>
          <form onSubmit={(e) => e.preventDefault()} className="flex flex-col text-left">
            
            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="First Name"
                  required
                />
                {errors.firstName && <p className="form-error">{errors.firstName}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Last Name"
                  required
                />
                {errors.lastName && <p className="form-error">{errors.lastName}</p>}
              </div>
            </div>

            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="contactNumber">Contact Number</label>
                <input
                  type="tel"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Contact Number"
                  required
                />
                {errors.contactNumber && <p className="form-error">{errors.contactNumber}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="email">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Email Address"
                  required
                />
                {errors.email && <p className="form-error">{errors.email}</p>}
              </div>
            </div>
            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="company">Company Name</label>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Company Name"
                />
                {errors.company && <p className="form-error">{errors.company}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="ABN">ABN</label>
                <input
                  type="text"
                  name="ABN"
                  value={formData.ABN}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="ABN"
                />
                {errors.ABN && <p className="form-error">{errors.ABN}</p>}
              </div>
            </div>
            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Address"
                  required
                />
                {errors.address && <p className="form-error">{errors.address}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="City"
                  required
                />
                {errors.city && <p className="form-error">{errors.city}</p>}
              </div>
            </div>
            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="State"
                  required
                />
                {errors.state && <p className="form-error">{errors.state}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="country">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Country"
                  required
                />
                {errors.country && <p className="form-error">{errors.country}</p>}
              </div>
            </div>
            <div className="register-field-container">
              <div className="register-field">
                <label className="form-label" htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Password"
                  required
                />
                {errors.password && <p className="form-error">{errors.password}</p>}
              </div>
              <div className="register-field">
                <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="form-fields w-full"
                  disabled={isPaymentMode}
                  placeholder="Confirm Password"
                  required
                />
                {errors.confirmPassword && <p className="form-error">{errors.confirmPassword}</p>}
              </div>
              </div>
            <div className="flex w-full max-w-[700px] mx-auto pt-[20px] pb-[25px] border-b-[2px] border-[#797972]">
              <label className="basis-1/3 flex justify-center">
                <input
                  type="radio"
                  name="membershipType"
                  value="type1"
                  checked={formData.membershipType === "type1"}
                  onChange={handleChange}
                  className="radio-square radio-membership-1"
                  disabled={isPaymentMode}
                />
                Membership 1
              </label>
              <label className="basis-1/3 flex justify-center">
                <input
                  type="radio"
                  name="membershipType"
                  value="type2"
                  checked={formData.membershipType === "type2"}
                  onChange={handleChange}
                  className="radio-square radio-membership-2"
                  disabled={isPaymentMode}
                />
                Membership 2
              </label>
              <label className="basis-1/3 flex justify-center">
                <input
                  type="radio"
                  name="membershipType"
                  value="type3"
                  checked={formData.membershipType === "type3"}
                  onChange={handleChange}
                  className="radio-square radio-membership-3"
                  disabled={isPaymentMode}
                />
                Membership 3
              </label>
            </div>
            
            <div className="flex w-full max-w-[600px] mx-auto pt-[25px] pb-[40px]">
              <label className="basis-1/2 flex justify-center">
              <input
                type="radio"
                name="paymentMethod"
                value="stripe"
                checked={formData.paymentMethod === "stripe"}
                onChange={handleChange}
                className="radio-square radio-membership-1"
                disabled={isPaymentMode}
              />{" "}
              Stripe
              </label>
              <label className="basis-1/2 flex justify-center">
              <input
                type="radio"
                name="paymentMethod"
                value="paypal"
                checked={formData.paymentMethod === "paypal"}
                onChange={handleChange}
                className="radio-square radio-membership-2"
                disabled={isPaymentMode}
              />{" "}
              PayPal
              </label>
            </div>

            {!isPaymentMode && (
              <Button handleOnClick={handleMakePayment} className="w-[240px] m-auto" buttonText={'Make Payment'}/>
            )}
            {isPaymentMode && (
              <>
                  {renderPaymentMethod()}
                  <Button handleOnClick={handleCancelPayment} className="w-[240px] m-auto mt-[25px] md:mt-[30px] lg:mt-[35px]" buttonText={'Cancel'}/>
              </>
            )}
          </form>
        </div>
      </div>
      {popup.show && <Popup message={popup.message} />}
      {showLoader && <Loader />}
    </div>
  );
};

export default UserRegister;
