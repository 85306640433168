import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import ResetPasswordComponent from '../../components/user/ResetPasswordComponent'

const ResetPassword = () => {
  return (
    <>
        <TopBar/>
        <ResetPasswordComponent/>
    </>
  )
}

export default ResetPassword