import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminSideBar from '../uiKit/AdminSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";


const AdminProjectUploadComponent = () => {
  const [projectData, setProjectData] = useState({
    name: "",
    startDate: "",
    shortDescription: "",
    description: "",
    image: null,
  });
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [fieldToConfirm, setFieldToConfirm] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate("/admin/login");
    }
  }, [navigate]);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setProjectData({ ...projectData, image: e.target.files[0] });
    } else {
      setProjectData({ ...projectData, [e.target.name]: e.target.value });
    }
  };

  const confirmUpdate = async () => {
    const token = localStorage.getItem("adminToken");
    const formData = new FormData();

    for (const key in projectData) {
      formData.append(key, projectData[key]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/projects`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        setPopup({ show: true, message: "Project upload failed" });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
        throw new Error("Project upload failed");
      }

      // alert("Project uploaded successfully!");
      setPopup({ show: true, message: "Project uploaded successfully!" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      setProjectData({
        name: "",
        startDate: "",
        shortDescription: "",
        description: "",
        image: null,
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Upload error:", error);
      // alert(error.message);
      setPopup({ show: true, message: error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    setFieldToConfirm("Current Project");
    setShowConfirmPopup(true); 
  };

  return (
    <div>
      <PageTitle title={"Admin Access"} />
        <div className='flex'>
          <AdminSideBar/>
          <div className="admin-container-dashboard pt-[20px]">
            <div className="admin-page-header">Project Page Update</div>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="register-field-container">
                  <div className="profile-details-fields">
                    <label className="form-label">Project Name</label>
                    <input
                      type="text"
                      name="name"
                      value={projectData.name}
                      onChange={handleChange}
                      placeholder="Project Name"
                      className="form-fields w-full bg-white"
                      required
                    />
                  </div>
                  <div className="profile-details-fields">
                    <label className="form-label">Project Date</label>
                    <input
                      type="date"
                      name="startDate"
                      value={projectData.startDate}
                      onChange={handleChange}
                      className="form-fields w-full bg-white"
                      required
                    />
                  </div>
                </div>
                <div className="register-field-container">
                  <div className="profile-details-fields w-full">
                    <label className="form-label">Project Short Description</label>
                    <textarea
                      name="shortDescription"
                      value={projectData.shortDescription}
                      onChange={handleChange}
                      placeholder="Project Short Description"
                      rows="5"
                      className="form-fields w-full bg-white"
                      required
                    />
                  </div>
                </div>
                <div className="register-field-container">
                  <div className="profile-details-fields w-full">
                    <label className="form-label">Project Description</label>
                    <textarea
                      name="description"
                      value={projectData.description}
                      onChange={handleChange}
                      placeholder="Project Description"
                      rows="5"
                      className="form-fields w-full bg-white"
                      required
                    />
                  </div>
                </div>
                <div className="register-field-container">
                  <div className="profile-details-fields w-full">
                    <label className="form-label">Project Picture</label>
                    <input
                      type="file"
                      name="image"
                      onChange={handleChange}
                      className="form-fields w-full bg-white"
                      ref={fileInputRef}
                      required
                    />
                  </div>
                </div>
                <div className="register-field-container">
                  <div className="profile-details-fields w-full">
                    <Button type="submit" className="lg:w-[240px] my-[20px] lg:mr-[30px]" buttonText={'Update'}/>
                  </div>
                </div>
              </form>
            </div>
        </div>
        {popup.show && (
          <Popup message={popup.message}/>
        )}
        {showConfirmPopup && (
          <Modal 
            fieldName={fieldToConfirm}
            onConfirm={() => {
              confirmUpdate();
              setShowConfirmPopup(false);
            }}
            onCancel={() => setShowConfirmPopup(false)}
            message={`Are you sure you want to update ${fieldToConfirm}?`}
          />
        )}
    </div>
  );
};

export default AdminProjectUploadComponent;
