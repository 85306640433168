import React, { useState, useEffect, useRef } from "react";
import AdminSideBar from '../uiKit/AdminSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons'; 
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";

const AdminProfileComponent = () => {
  const [adminData, setAdminData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    isEditable: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
    },
  });
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [fieldToConfirm, setFieldToConfirm] = useState("");
  const [errors, setErrors] = useState({});

  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  
  const validateField = (name, value) => {
    if (!value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} cannot be empty` }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const validatePassword = () => {
    let errors = {};
    if (!passwords.newPassword) {
      errors.newPassword = 'New password is required';
    } else if (passwords.newPassword.length < 8) {
      errors.newPassword = 'Password must be at least 8 characters long';
    } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(passwords.newPassword)) {
      errors.newPassword = 'Password must contain at least one capital letter and one number';
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setPasswordErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const confirmSave = (fieldName) => {
    setFieldToConfirm(fieldName);
    setShowConfirmPopup(true);
  };

  const fetchAdminData = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch admin data");
      }

      const data = await response.json();
      if (
        data &&
        data.firstName !== undefined &&
        data.lastName !== undefined &&
        data.email !== undefined
      ) {
        setAdminData((prevState) => ({
          ...prevState,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        }));
      } else {
        throw new Error("Invalid data structure received from API");
      }
    } catch (error) {
      console.error("Error fetching admin data:", error);
      // setError(error.message);
      setPopup({ show: true, message: error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData({ ...adminData, [name]: value });
    validateField(name, value); // Validate field immediately on change
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    let errors = { ...passwordErrors };

    // Password validation logic
    if (name === 'oldPassword') {
      // Include validation for oldPassword if necessary
    }

    if (name === 'newPassword') {
      if (!value) {
        errors.newPassword = 'New password is required';
      } else if (value.length < 8) {
        errors.newPassword = 'Password must be at least 8 characters long';
      } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(value)) {
        errors.newPassword = 'Password must contain at least one capital letter and one number';
      } else {
        errors.newPassword = '';
      }
    }

    if (name === 'confirmPassword') {
      if (value !== passwords.newPassword) {
        errors.confirmPassword = 'Passwords do not match';
      } else {
        errors.confirmPassword = '';
      }
    }

    setPasswordErrors(errors);
  };


  const handleSave = async (field) => {

    // if (!adminData[field]) {
    //   setErrors({ ...errors, [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} cannot be empty` });
    //   return;
    // }

    
    const token = localStorage.getItem("adminToken");
    try {
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/profile`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ [field]: adminData[field] }),
        }
      );

      if (!updateResponse.ok) {
        const errorData = await updateResponse.json();
        throw new Error(errorData.message || `Failed to update ${field}`);
      }

      const updatedAdmin = await updateResponse.json();
      setAdminData((prevState) => ({
        ...prevState,
        ...updatedAdmin.admin,
        isEditable: { ...prevState.isEditable, [field]: false },
      }));

      // alert(
      //   `${
      //     field.charAt(0).toUpperCase() + field.slice(1)
      //   } updated successfully!`
      // );
      setPopup({ show: true, message: `${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully!` });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      // setError(error.message);
      setPopup({ show: true, message: error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  const handlePasswordSave = async () => {

    if (!validatePassword()) {
      return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setPopup({ show: true, message: "New passwords do not match!" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      return;
    }

    const token = localStorage.getItem("adminToken");
    try {
      // Validate the old password
      const validationResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/validate-password`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password: passwords.oldPassword }),
        }
      );

      if (!validationResponse.ok) {
        const errorData = await validationResponse.json();
        throw new Error(errorData.message || "Old password is incorrect");
      }

      // Update the password
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/profile`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPassword: passwords.newPassword }),
        }
      );

      if (!updateResponse.ok) {
        const errorData = await updateResponse.json();
        throw new Error(errorData.message || "Failed to update password");
      }

      setShowPasswordFields(false);
      setPasswords({ oldPassword: "", newPassword: "", confirmPassword: "" });

      setPopup({ show: true, message: "Password updated successfully!" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    } catch (error) {
      console.error("Error updating password:", error);
      setPopup({ show: true, message: error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  const renderField = (label, name, type = "text") => {
    return (
      <div className="relative">
        <label className="form-label">{label}</label>
        <input
          ref={(el) => (inputRefs.current[name] = el)}
          type={type}
          name={name}
          value={adminData[name]}
          onChange={handleChange}
          className="form-fields w-full bg-white"
          readOnly={!adminData.isEditable[name]}
          style={{
            backgroundColor: adminData.isEditable[name] ? "white" : "#f0f0f0",
          }}
        />
        {errors[name] && <p className="form-error">{errors[name]}</p>}
        {adminData.isEditable[name] ? (
          <button type="button" onClick={() => confirmSave(name)} className="absolute top-[64px] right-[10px] transform -translate-y-1/2 z-10">
            <FontAwesomeIcon icon={faSquareCheck} className="text-primary text-[24px]"/>
          </button>
        ) : (
          <button type="button" onClick={() => handleEditFocus(name)} className="absolute top-[64px] right-[10px] transform -translate-y-1/2 z-10">
            <FontAwesomeIcon icon={faPenToSquare} className="text-[24px]"/>
          </button>
        )}
      </div>
    );
  };

  const inputRefs = useRef({});

  /* Enable This Code If You Want To Keep Multiple Fields In Changing State At A Time*/
  // const handleEditFocus = (field) => {
  //   setUserData((prevUserData) => ({
  //     ...prevUserData,
  //     isEditable: { ...prevUserData.isEditable, [field]: true },
  //   }));
  //   setTimeout(() => inputRefs.current[field]?.focus(), 0);
  // };

  /* Enable This Field If you Want To Keep Single Field In Changing State At A Time*/
  const handleEditFocus = (field) => {
    setAdminData((prevUserData) => ({
      ...prevUserData,
      isEditable: {
        // Set all other fields to false
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        
        [field]: true,
      },
    }));
    
    setTimeout(() => inputRefs.current[field]?.focus(), 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    Object.keys(adminData).forEach((field) => {
      if (field !== 'isEditable') {
        validateField(field, adminData[field]);
      }
    });
  
    // Check for any errors before proceeding
    if (Object.values(errors).some((error) => error !== '')) {
      return; // Stop the function if there's any error
    }

    handlePasswordSave();
  };

  const renderPasswordField = () => {
    if (showPasswordFields) {
      return (
        <>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <div className="relative">
              <label className="form-label">Old Password:</label>
              <input
                type="password"
                name="oldPassword"
                value={passwords.oldPassword}
                onChange={handlePasswordChange}
                className="form-fields w-full bg-white"
              />
            </div>
          </div>
          
          <div className="profile-details-fields">
            <div className="relative">
              <label className="form-label">New Password:</label>
              <input
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handlePasswordChange}
                className="form-fields w-full bg-white"
              />
              {passwordErrors.newPassword && <p className="form-error">{passwordErrors.newPassword}</p>}
            </div>
          </div>
          </div>
          <div className="register-field-container">
          <div className="profile-details-fields">
            <div className="relative">
              <label className="form-label">Confirm New Password:</label>
              <input
                type="password"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
                className="form-fields w-full bg-white"
              />
              {passwordErrors.confirmPassword && <p className="form-error">{passwordErrors.confirmPassword}</p>}
            </div>
          </div>
          </div>
          <div className="register-field-container">
            <div className="profile-details-fields">
                <Button type="submit" className="lg:w-[240px] my-[20px] lg:mr-[30px]" buttonText={'Save'}/>
                <Button handleOnClick={() => setShowPasswordFields(false)} className="lg:w-[240px] mb-[20px] bg-secondary-200 border-secondary-200 hover:text-secondary-200" buttonText={'Cancel'}/>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="register-field-container">
          <div className="profile-details-fields">
            <div className="relative">
              <label className="form-label">Password:</label>
              <input
                type="password"
                value="*********"
                readOnly
                style={{ backgroundColor: "#f0f0f0" }}
                className="form-fields w-full bg-white"
              />
              <button type="button" onClick={() => setShowPasswordFields(true)} className="absolute top-2/3 right-[10px] transform -translate-y-1/2 z-10">
                <FontAwesomeIcon icon={faPenToSquare} className="text-[24px]"/>
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <PageTitle title={"Admin Access"} />
        <div className='flex'>
          <AdminSideBar/>
          <div className="admin-container-dashboard">
            <div className="admin-page-header">Admin Profile</div>
            <form onSubmit={handleSubmit}>
              <div className="register-field-container">
                <div className="profile-details-fields">{renderField("First Name", "firstName")}</div>
                <div className="profile-details-fields">{renderField("Last Name", "lastName")}</div>
              </div>
              <div className="register-field-container">
                <div className="profile-details-fields w-full">{renderField("Email", "email", "email")}</div>
              </div>
              {renderPasswordField()}
            </form>
          </div>
        </div>
        {popup.show && (
          <Popup message={popup.message}/>
        )}
        {showConfirmPopup && (
          <Modal 
            fieldName={fieldToConfirm}
            onConfirm={() => {
              handleSave(fieldToConfirm);
              setShowConfirmPopup(false);
            }}
            onCancel={() => setShowConfirmPopup(false)}
          />
        )}
    </div>
  );
};

export default AdminProfileComponent;