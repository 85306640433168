import React from 'react'
import { NavLink } from 'react-router-dom';
import Logo from '../../pictures/logo.png'

const Footer = () => {
  return (
    <div className='flex flex-col md:flex-row px-[20px] py-[30px] md:pt-[35px] md:pb-[60px] lg:pt-[30px] lg:pb-[80px] border-[#3d3d3d] border-t-[2px]'>
        <div className='basis-[46%] text-center pb-[15px] bg:py-0'>
            <img src={Logo} alt='' className="md:m-auto w-[120px] md:w-[180px] lg:w-[300px]"/>
        </div>
        <div className='basis-[27%] py-[10px] lg:pt-[20px]'>
            <NavLink to="/dashboard" className="footer-menu-item">
                About Us
            </NavLink>
            <NavLink to="/profile" className="footer-menu-item">
                Blog
            </NavLink>
            <NavLink to="/projects" className="footer-menu-item">
                FAQS
            </NavLink>
        </div>
        <div className='basis-[27%] py-[10px] lg:pt-[20px]'>
            <NavLink to="/dashboard" className="footer-menu-item">
                Policies
            </NavLink>
            <NavLink to="/profile" className="footer-menu-item">
                Contact
            </NavLink>
        </div>
    </div>
  )
}

export default Footer