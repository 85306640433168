const plans = [
  {
    title: "type1",
    price: "$19 p/m",
    features: [
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
    ],
    background: "bg-[#8dc63e]",
  },
  {
    title: "type2",
    price: "$29 p/m",
    features: [
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
    ],
    background: "bg-[#59b245]",
  },
  {
    title: "type3",
    price: "$39 p/m",
    features: [
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
      "qui duis anim nulla",
    ],
    background: "bg-[#126603]",
  },
];

export default plans;
