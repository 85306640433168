import React from 'react'
import UserSideBar from '../../components/uiKit/UserSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import MembershipPlan from "./MembershipPlan";

const UserMembershipComponent = () => {
  return (
    <div>
      <PageTitle title={"Membership"} />
        <div className='flex'>
          <UserSideBar/>
          <div className="container-dashboard px-[20px] md:px-[30px] lg:px-[40px] pb-[40px]">
          <MembershipPlan/>
          </div>
        </div>
    </div>
  )
}

export default UserMembershipComponent