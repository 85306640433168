import React from 'react'
import Button from '../uiKit/Button'

const camelCaseToTitle = (camelCase) => {
  if (!camelCase) return '';
  const result = camelCase.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const Modal = ({ fieldName, onConfirm, onCancel, message }) => {
  const formattedFieldName = camelCaseToTitle(fieldName);
  return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-7 rounded shadow-lg z-50 text-center">
          <p>{message || `Are you sure you want to update ${formattedFieldName}?`}</p>
          <div className="flex justify-between mt-[15px]">
            <Button handleOnClick={onConfirm} className="w-[150px]" buttonText={'Sure'}/>
            <Button handleOnClick={onCancel} className="w-[150px] ml-[30px] bg-secondary-200 border-secondary-200 hover:text-secondary-200" buttonText={'Cancel'}/>
          </div>
        </div>
      </div>
    );
  };
  
export default Modal