import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("adminToken");

    useEffect(() => {
      if (!token) {
        navigate("/admin/login");
      }
    }, [navigate, token]);

    if (!token) {
      // Return null or a placeholder while waiting for the effect to run
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAdminAuth;
