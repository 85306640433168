import React, { useState, useEffect } from "react";
import AdminSideBar from '../uiKit/AdminSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";

const AdminCarbonPriceUpdateComponent = () => {
  const [price, setPrice] = useState("");
  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [fieldToConfirm, setFieldToConfirm] = useState("");

  const fetchCurrentPrice = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/carbon-price`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Failed to fetch current carbon price"
        );
      }

      const data = await response.json();
      setPrice(data.price);
    } catch (error) {
      console.error("Error fetching current carbon price:", error);
      alert(error.message);
    }
  };

  useEffect(() => {
    fetchCurrentPrice();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    setFieldToConfirm("Carbon Price");
    setShowConfirmPopup(true); 
  };

  const confirmUpdate = async () => {
    const token = localStorage.getItem("adminToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/carbon-price`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ price }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        
        setPopup({ show: true, message: errorData.message || "Failed to update carbon price" });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
        throw new Error(errorData.message || "Failed to update carbon price");
      }

      
      setPopup({ show: true, message: "Carbon price updated successfully" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      fetchCurrentPrice();
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    }
  };

  return (
      <div>
      <PageTitle title={"Admin Access"} />
        <div className='flex'>
          <AdminSideBar/>
          <div className="admin-container-dashboard">
            <div className="admin-page-header">Carbon Price Update</div>
              <form onSubmit={handleSubmit}>
                <div className="register-field-container">
                  <div className="profile-details-fields">
                  <div className="relative">
                    <label className="form-label">
                      Live Carbon Price Today:
                    </label>
                    <div className="flex items-center">
                      <span className="form-fields-prefix">$</span>
                      <input
                        type="number"
                        step="0.1"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="form-fields w-full bg-white pl-[33px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="register-field-container">
                <div className="profile-details-fields">
                  <Button type="submit" className="lg:w-[240px] my-[20px] lg:mr-[30px]" buttonText={'Update'}/>
                </div>
              </div>
            </form>
          </div>
      </div>
      {popup.show && (
          <Popup message={popup.message}/>
      )}
      {showConfirmPopup && (
        <Modal
          fieldName={fieldToConfirm}
          onConfirm={() => {
            confirmUpdate();
            setShowConfirmPopup(false);
          }}
          onCancel={() => setShowConfirmPopup(false)}
          message={`Are you sure you want to update ${fieldToConfirm}?`}
        />
      )}
    </div>
  );
};

export default AdminCarbonPriceUpdateComponent;
