import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserSideBar from '../uiKit/UserSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";
import Loader from "../uiKit/Loader";

const CancelMembershipComponent = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const confirmSave = () => {
    setShowConfirmPopup(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data);
        // setLoading(false);
        setShowLoader(false)
      })
      .catch((error) => {
        // console.error("Error fetching user data:", error);
        // setError(error.message);
        setPopup({ show: true, message: "Error fetching user data:", error });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      });
  }, []);

  const handleCancel = async () => {
    const token = localStorage.getItem("token");
    setShowLoader(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/cancel-subscription`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setPopup({ show: true, message: "Subscription cancelled successfully" });
      setTimeout(() => {
        setPopup({ show: false, message: "" });
        setShowLoader(false)
        navigate("/membership");
      }, 2000);
    } else {
      setShowLoader(false)
      setPopup({ show: true, message: "Failed to cancel subscription" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <PageTitle title="Cancel Membership"/>
        <div className='flex'>
          <UserSideBar/>
          <div className="container-dashboard px-[20px] md:px-[30px] lg:px-[120px] py-[20px] md:py-[30px] lg:py-[40px] pb-[40px]">
            <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
            {userData.subscriptionStatus === "active" ? (
            <>
              <button onClick={() => confirmSave()} className="text-primary text-[18px] md:text-[20px] lg:text-lg hover:font-bold py-[20px] md:py-[30px]">Cancel Membership</button>
            </>
            ) : (
              <>
                <div className="text-lg font-bold-header pt-[20px] lg:pt-[30px] leading-[30px]">You are not subscribed to any membership.</div>
                <button onClick={() => navigate("/membership")} className="text-primary text-[18px] md:text-[20px] lg:text-lg hover:font-bold py-[20px] md:py-[30px]">Select Plan</button>
              </>
            )}
          </div>
        </div>
        {popup.show && (
          <Popup message={popup.message}/>
        )}
        {showConfirmPopup && (
          <Modal 
            onConfirm={() => {
              handleCancel();
              setShowConfirmPopup(false);
              setPopup({ show: true, message: "Membership has been canceled" });
              setTimeout(() => setPopup({ show: false, message: "" }), 2000);
            }}
            onCancel={() => setShowConfirmPopup(false)}
            message="Are you sure you want to cancel your subscription?"
          />        
        )}
        {showLoader && <Loader />}
    </div>
  );
};

export default CancelMembershipComponent;
