import React, { useState } from "react";
import Logo from '../../pictures/logo.png'
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Loader from "../uiKit/Loader";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState({ show: false, message: "" });

  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);

    fetch(`${process.env.REACT_APP_API_URL}/api/users/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          setShowLoader(false);
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setShowLoader(false);
        setPopup({ show: true, message: "Password reset email sent" });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowLoader(false);
        setPopup({ show: true, message: "Failed to send password reset email: " + error.message});
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      });
  };

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block max-w-[550px]">
        <div className="max-w-[350px] m-auto pb-[10px]">
          <img src={Logo} alt='' className="m-auto site-logo"/>
          <h1 className="text-lg md:text-3xl lg:text-5xl my-[10px] lg:my-[15px] font-bold-header">Forgot Password</h1>
          <p>Send password reset email to your email</p>
        </div>
        <form onSubmit={handleSubmit} className="text-left">
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter your email"
                required
              />
            </div>
          </div>
          <Button type="submit" className="my-[5px]" buttonText={'Send'}/>
        </form>
        </div>
        {popup.show && (
          <Popup message={popup.message}/>
        )}
        {showLoader && <Loader />}
    </div>
  );
};

export default ForgotPasswordComponent;
