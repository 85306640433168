import React from "react";
import Logo from '../../pictures/logo.png'
import MembershipPlan from "./MembershipPlan";


const Membership = () => {

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block lg:px-[80px] pb-[25px] md:pb-[45px] lg:pb-[65px]">
      <div className="">
          <img src={Logo} alt='' className="m-auto site-logo"/>
          <h1 className="page-header">Start Fixing Your Planet</h1>
          <h2 className="secondary-header">Membership Options</h2>
          <p className="pt-[8px]">Flexiable Memberships. Cancel anytime.</p>
        </div>
        <MembershipPlan/>
      </div>
    </div>
  );
  
};

export default Membership;
