import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import PaymentHistoryComponent from '../../components/user/PaymentHistoryComponent'
import Footer from '../../components/uiKit/Footer'

const PaymentHistory = () => {
  return (
    <>
        <TopBar/>
        <PaymentHistoryComponent/>
        <Footer/>
    </>
  )
}

export default PaymentHistory