import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Modal from "../uiKit/Modal";

const UserSideBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [logoutConfirmation, setLogoutConfirmation] = useState(false)

  const handleLogout = () => {
    //remove token from local storage
    localStorage.removeItem("token");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  return (
    <>
      {!isSidebarOpen && (
        <div
          className="absolute px-[20px] top-[23px] md:top-[30px] translate-y-[-50%]"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            icon={faBars}
            className="text-white text-[20px] md:text-[25px] lg:hidden"
          />
        </div>
      )}

      <div
        className={`fixed inset-y-0 left-0 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition duration-300 ease-in-out z-30 w-full bg-primary text-white min-h-screen overflow-y-auto lg:static lg:transform-none lg:basis-[25%]`}
      >
        <div className="absolute top-0 right-0 p-5" onClick={toggleSidebar}>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-white text-[20px] md:text-[25px] lg:hidden"
          />
        </div>

        <div className="px-[20px] py-[30px] max-w-[85%] lg:max-w-[250px] xl:pt-[50px] xl:max-w-[350px] m-auto">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? "sidebar-menu-item font-bold hover:font-bold xl:text-[22px]"
                : "sidebar-menu-item hover:font-bold xl:text-[22px]"
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive
                ? "sidebar-menu-item font-bold hover:font-bold xl:text-[22px]"
                : "sidebar-menu-item hover:font-bold xl:text-[22px]"
            }
          >
            Your Profile
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive }) =>
              isActive
                ? "sidebar-menu-item font-bold hover:font-bold xl:text-[22px]"
                : "sidebar-menu-item hover:font-bold xl:text-[22px]"
            }
          >
            Project Insights
          </NavLink>

          <div
            className="flex items-center justify-between xl:text-[22px]"
            onClick={toggleAccountMenu}
          >
            Account{" "}
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${isAccountMenuOpen ? "rotate-180" : ""}`}
            />
          </div>
          {isAccountMenuOpen && (
            <div className="mt-2 pl-[15px]">
              <NavLink
                to="/user-membership"
                onClick={toggleSidebar}
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-menu-item font-bold leading-[35px] hover:font-bold xl:text-[22px]"
                    : "sidebar-menu-item leading-[35px] hover:font-bold xl:text-[22px]"
                }
              >
                Membership
              </NavLink>
              <NavLink
                to="/payment-history"
                onClick={toggleSidebar}
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-menu-item font-bold leading-[35px] hover:font-bold xl:text-[22px]"
                    : "sidebar-menu-item leading-[35px] hover:font-bold xl:text-[22px]"
                }
              >
                Payment History
              </NavLink>
              <NavLink
                to="/cancel-membership"
                onClick={toggleSidebar}
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-menu-item font-bold leading-[35px] hover:font-bold xl:text-[22px]"
                    : "sidebar-menu-item leading-[35px] hover:font-bold xl:text-[22px]"
                }
              >
                Cancel Membership
              </NavLink>
            </div>
          )}

          <button onClick={() => setLogoutConfirmation(true)} className="sidebar-menu-item hover:font-bold xl:text-[22px]">
            Logout
          </button>
        </div>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-20 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {logoutConfirmation && (
          <Modal 
            onConfirm={() => {
              handleLogout();
              setLogoutConfirmation(false);
            }}
            onCancel={() => setLogoutConfirmation(false)}
            message={`Are you sure you want to logout?`}
          />
      )}
    </>
  );
};

export default UserSideBar;
