import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import UserRegisterComponent from '../../components/user/UserRegisterComponent'

const UserRegister = () => {
  return (
    <>
        <TopBar/>
        <UserRegisterComponent/>
    </>
  )
}

export default UserRegister