import React from 'react'
import { useNavigate } from "react-router-dom";
import Logo from "../../pictures/logo.png";
import Button from "../../components/uiKit/Button"

const NotFoundAdmin = () => {

    const navigate = useNavigate();

    const navigateToLogin = () => {
      navigate("/admin/login"); 
    };

    return (
        <div className="container-form">
            <div className="transparent-bg inline-block max-w-[550px] mt-[60px] md:mt-[120px] lg:mt-[180px]">
                <div className="max-w-[350px] m-auto pb-[10px]">
                    <img src={Logo} alt="" className="m-auto site-logo" />
                        <div className='pt-[20px]'>
                            <h1 className="font-extrabold text-[26px] md:text-[45px] lg:text-[65px]">404</h1>
                            <p className='font-semibold text-[17px] md:text-[21px] lg:text-[25px] pt-[10px] pb-[10px] md:pt-[15px] md:pb-[15px] lg:pt-[20px] lg:pb-[30px]'>NOT FOUND</p>
                            <Button handleOnClick={navigateToLogin} buttonText={"Back to Login"} />
                        </div>
                </div>
            </div>

        </div>
    )
}

export default NotFoundAdmin
