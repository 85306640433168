import React, { useState, useEffect } from "react";
import UserSideBar from "../../components/uiKit/UserSideBar";
import PageTitle from "../../components/uiKit/PageTitle";
import Button from "../uiKit/Button";
import Pagination from "../uiKit/Pagination";

const ProjectInsightsComponent = () => {
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [latestProject, setLatestProject] = useState(null);
  const [viewingAllProjects, setViewingAllProjects] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust as needed

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate the currently displayed projects
  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // Determine if pagination should be displayed
  const shouldDisplayPagination = projects.length > itemsPerPage;

  useEffect(() => {
    fetchLatestProject();
  }, []);

  const fetchProjects = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/projects`
    );
    let data = await response.json();

    // Map through the projects to replace imageUrl with a pre-signed URL
    const projectsWithPresignedUrls = await Promise.all(
      data.map(async (project) => {
        const presignedUrl = await fetchPresignedUrl(project.imageUrl);
        return { ...project, imageUrl: presignedUrl }; // Replace the imageUrl with the presignedUrl
      })
    );

    setProjects(projectsWithPresignedUrls);
  };

  const fetchLatestProject = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/projects/latest`
    );
    const data = await response.json();

    // Replace its imageUrl with a pre-signed URL
    const presignedUrl = await fetchPresignedUrl(data.imageUrl);
    const updatedProject = { ...data, imageUrl: presignedUrl }; // Replace the imageUrl with the presignedUrl

    setCurrentProject(updatedProject);
    setLatestProject(updatedProject); // Assuming you want to update both states
  };

  const handleViewAllProjects = () => {
    fetchProjects();
    setViewingAllProjects(true);
    setSelectedProject(null);
  };

  const handleBackToCurrentProject = () => {
    setSelectedProject(null);
    setCurrentProject(latestProject);
    setViewingAllProjects(false);
  };

  const handleViewProject = (project) => {
    setSelectedProject(project);
    setViewingAllProjects(false);
  };

  const fetchPresignedUrl = async (imageUrl) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/presigned-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ imageUrl }),
        }
      );
      const data = await response.json();
      return data.presignedUrl;
    } catch (error) {
      console.error("Error fetching pre-signed URL:", error);
    }
  };

  // Determine which project to display
  const displayProject = selectedProject || currentProject;

  return (
    <div>
      <PageTitle
        title={viewingAllProjects ? "All Projects" : "Project Details"}
      />
      <div className="flex">
        <UserSideBar />
        <div className="container-dashboard px-[20px] md:px-[30px] lg:px-[40px] pb-[40px]">
          {viewingAllProjects ? (
            <>
              <div className="flex flex-wrap -mx-[8px]">
                {currentProjects.map((project, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/2 lg:w-1/3 px-[10px] lg:px-[15px] mb-[20px] lg:mb-[30px]"
                  >
                    <div className="shadow-lg bg-[#f5f5f5]">
                      <div className="p-[15px] md:p-[20px] lg:p-[25px]">
                        <img
                          src={`${project.imageUrl}`}
                          alt={project.name}
                          className="w-full max-h-[250px]"
                        />
                        <h3 className="pt-[15px] lg:pt-[20px] text-[18px] md:text-[20px] lg:text-lg font-semibold">
                          {project.name}
                        </h3>
                      </div>
                      <Button
                        handleOnClick={() => handleViewProject(project)}
                        buttonText={"View"}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {shouldDisplayPagination && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={projects.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              )}
              <button
                onClick={handleBackToCurrentProject}
                className="text-primary text-lg duration-300 hover:scale-105 pt-[10px]"
              >
                Back to Current Project
              </button>
            </>
          ) : (
            <>
              {displayProject && (
                <div>
                  <div className="flex flex-col lg:flex-row flex-wrap">
                    <div className="basis-1/2">
                      <img
                        src={`${displayProject.imageUrl}`}
                        alt={displayProject.name}
                        className="w-full"
                      />
                    </div>
                    <div className="basis-1/2 lg:pl=[25px]">
                      <h2 className="text-[18px] md:text-[20px] lg:text-lg font-semibold leading-[30px] lg:leading-[40px]">
                        Project name: {displayProject.name}
                      </h2>
                      <h2 className="text-[18px] md:text-[20px] lg:text-lg font-semibold leading-[30px] lg:leading-[40px]">
                        Project Start Date:{" "}
                        {new Date(
                          displayProject.startDate
                        ).toLocaleDateString()}
                      </h2>
                      <p className="pt-[10px] md:pt-[15px]">
                        {displayProject.shortDescription}
                      </p>
                    </div>
                  </div>
                  <div className="pt-[10px] md:pt-[15px] pb-[20px] lg:pb-[35px]">
                    <p>{displayProject.description}</p>
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row items-start">
                <button
                  onClick={handleViewAllProjects}
                  className="text-primary text-[18px] md:text-[20px] lg:text-lg transition duration-300 hover:scale-105 pb-[15px] md:pb-[0px]"
                >
                  View All Projects
                </button>
                {selectedProject && (
                  <button
                    onClick={handleBackToCurrentProject}
                    className="text-primary text-[18px] md:text-[20px] lg:text-lg duration-300 hover:scale-105 md:pl-[30px]"
                  >
                    Back to Current Project
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectInsightsComponent;
