import React from 'react'
import Logo from '../../pictures/logo.png';

function PageTitle({title}) {
  return (
    <div className='py-[35px] md:py-[50px] lg:py-[55px] text-center page-title-background relative'>
        <h1 className='page-header text-white lg:text-accent-100'>{title}</h1>
        <img src={Logo} alt='' className="site-logo w-[200px] absolute top-[20px] left-[50px] hidden lg:block"/>
    </div>
  )
}

export default PageTitle