// src/components/user/StripePaymentForm.js
import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../uiKit/Button";

const StripePaymentForm = ({ formData, onPaymentSuccess, onPaymentError }) => {
  const stripe = useStripe();
  const elements = useElements();

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#000000',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  const getStripePriceId = (membershipType) => {
    switch (membershipType) {
      case "type1":
        return process.env.REACT_APP_STRIPE_PRICE_ID_TYPE1;
      case "type2":
        return process.env.REACT_APP_STRIPE_PRICE_ID_TYPE2;
      case "type3":
        return process.env.REACT_APP_STRIPE_PRICE_ID_TYPE3;
      default:
        return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      const stripePriceId = getStripePriceId(formData.membershipType);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("Error creating payment method:", error);
        onPaymentError(error);
        return;
      }

      try {
        const subscriptionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/subscribe-stripe`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              paymentMethodId: paymentMethod.id,
              stripePriceId,
              formData,
            }),
          }
        );

        if (!subscriptionResponse.ok) {
          throw new Error("Failed to create subscription");
        }

        const subscriptionData = await subscriptionResponse.json();
        onPaymentSuccess(
          subscriptionData.subscriptionId,
          subscriptionData.paymentDetails
        ); // Send subscription data to UserRegister
      } catch (error) {
        onPaymentError(error);
      }
    }
  };
  return (
    <>
      <div className="mb-6">
        <CardElement options={cardElementOptions} className="form-fields" />
      </div>
      <Button handleOnClick={handleSubmit} className="w-[240px] m-auto" buttonText={'Make Payment'}/>
    </>
  );
};

export default StripePaymentForm;
