import React, { useState, useEffect } from "react";
import AdminSideBar from "../uiKit/AdminSideBar";
import PageTitle from "../../components/uiKit/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../uiKit/Pagination";

const AdminCurrentMembersComponent = () => {
  const [users, setUsers] = useState([]);

  // State for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Can be adjusted as needed

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate the currently displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);
  const shouldDisplayPagination = users.length > itemsPerPage;

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  const fetchActiveUsers = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/current-members`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Fetch Error:", error);
    }
  };

  const handleCancelSubscription = async (userId) => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/cancel-subscription/${userId}`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error("Failed to cancel subscription");
      alert("Subscription cancelled successfully");
      fetchActiveUsers(); // Refresh the list
    } catch (error) {
      console.error("Cancellation Error:", error);
    }
  };

  const convertToCSV = (users) => {
    const rows = []; // Array to hold CSV rows

    // Adding header row
    rows.push(
      [
        "Name",
        "Contact Number",
        "Email",
        "Company",
        "ABN",
        "Address",
        "City",
        "State",
        "Country",
        "Membership Type",
        "Subscription Status",
        "Trees Planted",
        "Has Taken Quiz",
        "Quiz Type",
        "Quiz Points",
      ]
        .map((field) => `"${field}"`)
        .join(",")
    ); // Enclose headers in quotes

    // Adding data rows
    users.forEach((user) => {
      rows.push(
        [
          `"${user.firstName} ${user.lastName}"`,
          `"${user.contactNumber}"`,
          `"${user.email}"`,
          `"${user.company}"`,
          `"${user.ABN}"`,
          `"${user.address}"`,
          `"${user.city}"`,
          `"${user.state}"`,
          `"${user.country}"`,
          `"${user.membershipType}"`,
          `"${user.subscriptionStatus}"`,
          `"${user.treesPlanted}"`,
          `"${user.hasTakenQuiz}"`,
          `"${user.quizType}"`,
          `"${user.quizPoints}"`,
        ].join(",")
      ); // Enclose each field in quotes
    });

    return rows.join("\n");
  };

  // Function to handle export to CSV
  const handleExport = () => {
    const csvString = convertToCSV(users);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "current_members.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownloadQuizResults = async (userId) => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/quiz-results/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch quiz results");
      const data = await response.json();

      // Check if data.csv is present and is a string
      if (data.csv && typeof data.csv === "string") {
        initiateDownload(data.csv, `${userId}_quiz_results.csv`);
      } else {
        console.error("Quiz data is not in the correct format:", data);
      }
    } catch (error) {
      console.error("Error fetching quiz results:", error);
    }
  };

  const initiateDownload = (csvString, filename) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <PageTitle title={"Admin Access"} />
      <div className="flex">
        <AdminSideBar />
        <div className="admin-container-dashboard pt-[20px]">
          <div className="md:px-[9px] lg:px-[13px]">
            <div className="flex justify-between">
              <div className="admin-page-header md:px-[0px] lg:px-[0px]">
                Current Members
              </div>
              <div
                className="lg:pr-[20px] cursor-pointer transition duration-300 hover:text-primary hover:scale-105"
                onClick={handleExport}
              >
                Export <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
            <div className="flex border-b-[2px] border-[#3d3d3d] pb-[20px] mb-[20px] font-extrabold">
              <div className="basis-[23%] min-w-[70px] max-w-[23%] font-Poppins">Name</div>
              <div className="basis-[28%] min-w-[70px] max-w-[28%] font-Poppins">
                Company
              </div>
              <div className="basis-[28%] min-w-[70px] max-w-[28%] font-Poppins">
                Membership
              </div>
              <div className="basis-[10%] min-w-[70px] max-w-[10%] text-center font-Poppins">
                Quiz Results
              </div>
            </div>
            {currentItems.map((user) => (
              <div key={user._id} className="flex py-[4px]">
                <div className="basis-[23%] min-w-[70px] max-w-[23%]">{`${user.firstName} ${user.lastName}`}</div>
                <div className="basis-[28%] min-w-[70px] max-w-[28%]">
                  {user.company}
                </div>
                <div className="basis-[28%] min-w-[70px] max-w-[28%]">
                  {user.membershipType}
                </div>
                <div className="basis-[10%] min-w-[70px] max-w-[10%] text-center">
                  {user.hasTakenQuiz ? (
                    <div className="hover:text-primary cursor-pointer transition duration-300" onClick={() => handleDownloadQuizResults(user._id)}>
                      <FontAwesomeIcon icon={faDownload} />
                    </div>                  
                  ) : (
                    <span>Not Taken</span>
                  )}
                </div>
                <div className="basis-[11%]">
                  <button
                    onClick={() => handleCancelSubscription(user._id)}
                    className="hover:text-[#1e6c0f] hover:font-semibold"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ))}
            {shouldDisplayPagination && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={users.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCurrentMembersComponent;
