// src/components/hoc/withUserAuth.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withUserAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
      if (!token) {
        navigate("/login");
      }
    }, [navigate, token]);

    if (!token) {
      // Return null or a placeholder while waiting for the effect to run
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withUserAuth;
