import React from 'react'

const ProgressBar = ({progress}) => {
  return (
    <div className="flex border-[2px] border-[#3d3d3d]">
        <div className="bg-primary text-xs text-blue-100 text-center leading-none py-[4px] outline-[2px]" style={{ width: `${progress}%` }}>
            {progress}% 
        </div>
    </div>
  )
}

export default ProgressBar