import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import UserDashboardComponent from '../../components/user/UserDashboardComponent'
import Footer from '../../components/uiKit/Footer'

const UserDashboard = () => {
  return (
    <>
        <TopBar/>
        <UserDashboardComponent/>
        <Footer/>
    </>
  )
}

export default UserDashboard