import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from '../uiKit/Button'
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";

const AdminEditProject = ({ projectId, onUpdate, onCancel }) => {
  const [projectData, setProjectData] = useState({
    name: "",
    startDate: "",
    shortDescription: "",
    description: "",
  });
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [fieldToConfirm, setFieldToConfirm] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate("/admin/login");
    }

    fetchProjectDetails();
  }, [projectId]);

  const fetchProjectDetails = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch project details");
      }
      const project = await response.json();
      setProjectData({
        ...project,
        startDate: project.startDate.split("T")[0], // Convert to 'YYYY-MM-DD' format
        image: null,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setFile(e.target.files[0]);
    } else {
      setProjectData({ ...projectData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    setFieldToConfirm("Current Project");
    setShowConfirmPopup(true); 
  };

  const confirmUpdate = async () => {
    const token = localStorage.getItem("adminToken");
    const formData = new FormData();

    Object.keys(projectData).forEach((key) => {
      formData.append(key, projectData[key]);
    });

    if (file) {
      formData.append("image", file, file.name);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Project update failed");
      }

      setPopup({ show: true, message: "Project updated successfully!" });
      setTimeout(() => {
        setPopup({ show: false, message: "" });
        
        setProjectData({
          name: "",
          startDate: "",
          shortDescription: "",
          description: "",
        });
        setFile(null);
        onUpdate()
        fileInputRef.current.value = "";
        navigate("/admin/projects");
      }, 2000);

    } catch (error) {
      console.error("Update error:", error);
      setPopup({ show: true, message: error.message });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-[20px] py-[25px] md:px-[30px] md:py-[40px] rounded shadow-lg w-[90%] md:w-[75%] lg:w-[65%] max-w-[900px]">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="register-field-container">
            <div className="profile-details-fields pb-[10px]">
              <label className="form-label">Project Name</label>
              <input
                type="text"
                name="name"
                value={projectData.name}
                onChange={handleChange}
                placeholder="Project Name"
                className="form-fields w-full bg-white"
                required
              />
            </div>
            <div className="profile-details-fields pb-[10px]">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                name="startDate"
                value={projectData.startDate}
                onChange={handleChange}
                className="form-fields w-full bg-white"
                required
              />
            </div>
          </div>

          <div className="register-field-container">
            <div className="profile-details-fields w-full pb-[10px]">
              <label className="form-label">Short Description</label>
              <textarea
                name="shortDescription"
                value={projectData.shortDescription}
                onChange={handleChange}
                className="form-fields w-full bg-white"
                placeholder="Short Description"
                rows="4"
                required
              />
            </div>
          </div>

          <div className="register-field-container">
            <div className="profile-details-fields w-full pb-[10px]">
              <label className="form-label">Project Description</label>
              <textarea
                name="description"
                value={projectData.description}
                onChange={handleChange}
                className="form-fields w-full bg-white"
                placeholder="Project Description"
                rows="4"
                required
              />
            </div>
          </div>

          <div className="register-field-container">
            <div className="profile-details-fields w-full pb-[10px]">
              <label className="form-label">Project Picture</label>
              <input
                type="file"
                name="image"
                onChange={handleChange}
                className="form-fields w-full bg-white"
                ref={fileInputRef}
              />
            </div>
          </div>
          <div className="register-field-container">
            <div className="profile-details-fields w-full flex justify-between mt-[15px]">
                <Button type="submit" className="w-[150px] md:w-[200px] lg:w-[240px]" buttonText={'Update'}/>
                <Button handleOnClick={onCancel} className="w-[150px] md:w-[200px] lg:w-[240px] ml-[30px] bg-secondary-200 border-secondary-200 hover:text-secondary-200" buttonText={'Cancel'}/>
            </div>
          </div>
        </form>
      </div>
      {popup.show && (
          <Popup message={popup.message}/>
        )}
      {showConfirmPopup && (
        <Modal 
          fieldName={fieldToConfirm}
          onConfirm={() => {
            confirmUpdate();
            setShowConfirmPopup(false);
          }}
          onCancel={() => setShowConfirmPopup(false)}
          message={`Are you sure you want to update ${fieldToConfirm}?`}
        />
      )}
    </div>
  );
};

export default AdminEditProject;
