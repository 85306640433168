import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserSideBar from '../uiKit/UserSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'; 
import Pagination from "../uiKit/Pagination";

const PaymentHistoryComponent = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  // const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();


  // State for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Can be adjusted as needed

  // Function to change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Calculate the currently displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = paymentHistory.slice(indexOfFirstItem, indexOfLastItem);



  useEffect(() => {
    // Fetch payment history
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/api/users/payment-history`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPaymentHistory(data);
      })
      .catch((error) => {
        console.error("Error fetching payment history:", error);
        setError(error.message);
      });
  }, []);

  const generateInvoiceDetails = async (payment) => {
    const subscriptionId = payment.invoiceUrl.split("/").pop();

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/paypal-subscription/${subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const subscriptionDetails = await response.json();

      const startDate = payment.paymentDate;
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 30);

      const invoiceDetails = {
        invoiceId: payment._id,
        invoiceDate: new Date(startDate).toLocaleDateString(),
        fullName:
          subscriptionDetails.subscriber.name.given_name +
          " " +
          subscriptionDetails.subscriber.name.surname,
        email: subscriptionDetails.subscriber.email_address,
        subscriptionStatus: subscriptionDetails.status,
        subscriptionStartDate: new Date(startDate).toLocaleDateString(),
        subscriptionEndDate: new Date(endDate).toLocaleDateString(),
        currency:
          subscriptionDetails.billing_info.last_payment.amount.currency_code,
        planId: subscriptionDetails.plan_id,
        planName: payment.details,
        quantity: subscriptionDetails.quantity,
        amountPaid: subscriptionDetails.billing_info.last_payment.amount.value,
        nextBillingDate: new Date(
          subscriptionDetails.billing_info.next_billing_time
        ).toLocaleDateString(),
      };

      return invoiceDetails;
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      setError(error.message);
      return null;
    }
  };

  const handleViewInvoice = async (payment) => {
    const invoiceDetails = await generateInvoiceDetails(payment);
    if (invoiceDetails) {
      navigate(`/paypal-invoice/${invoiceDetails.invoiceId}`, {
        state: { invoiceDetails },
      });
    }
  };

  return (
    <div>
      <PageTitle title="Payment History" />
        <div className='flex'>
          <UserSideBar/>
          <div className="container-dashboard px-[20px] md:px-[30px] lg:px-[120px] py-[20px] md:py-[30px] lg:py-[40px] pb-[40px]">
          {error && <div className="text-lg font-bold-header pt-[20px] pb-[20px] md:pb-[30px] lg:pb-[45px]">Error: {error}</div>}

            <div className="flex border-b-[2px] border-[#3d3d3d] pb-[20px] mb-[20px] font-semibold">
              <div className="basis-[30%] min-w-[70px] max-w-[301%] font-Poppins">Payment Date</div>
              <div className="basis-[40%] min-w-[70px] max-w-[40%] font-Poppins">Details</div>
              <div className="basis-[24%] min-w-[70px] max-w-[24%] font-Poppins">Amount</div>
              <div className="basis-[6%] min-w-[70px] max-w-[6%] font-Poppins">Invoice</div>
            </div>
          
              {paymentHistory.length > 0 ? (
                currentItems.map((payment, index) => (
                  <div key={index}  className="flex py-[4px]">
                    <div className="basis-[30%] min-w-[70px] max-w-[30%]">{new Date(payment.paymentDate).toLocaleDateString()}</div>
                    <div className="basis-[40%] min-w-[70px] max-w-[40%]">{payment.details}</div>
                    <div className="basis-[24%] min-w-[70px] max-w-[24%]">${payment.amount}</div>
                    {payment.paymentMethod === "paypal" ? (
                      <div className="basis-[6%] min-w-[70px] max-w-[6%]">
                        <button onClick={() => handleViewInvoice(payment)} className="hover:font-semibold">
                          Details
                        </button>
                      </div>
                    ) : (
                      <div className="basis-[6%] min-w-[70px] text-center">
                        <a
                          href={payment.invoiceUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-lg font-bold-header pt-[20px] pb-[20px] md:pb-[30px] lg:pb-[45px]">No payment history available.</div>
              )}
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={paymentHistory.length}
                paginate={paginate}
                currentPage={currentPage}
              />
          </div>
          
        </div>
        
    </div>
  );
};

export default PaymentHistoryComponent;
