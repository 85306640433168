import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import AdminCanceledMembersComponent from '../../components/admin/AdminCanceledMembersComponent'
import Footer from '../../components/uiKit/Footer'

const AdminCanceledMembers = () => {
  return (
    <>
        <TopBar/>
        <AdminCanceledMembersComponent/>
        <Footer/>
    </>
  )
}

export default AdminCanceledMembers