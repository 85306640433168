import React from 'react'

const Popup = ({ message }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-[40px] py-[40px] rounded shadow-lg z-50 min-h-[120px] min-w-[300px] flex items-center justify-center">
            {message}
        </div>
    </div>
  );
};

export default Popup;
