import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from '../../pictures/logo.png'
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import ProgressBar from "../uiKit/ProgressBar";

const QuizComponent = () => {
  const [quizType, setQuizType] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [popup, setPopup] = useState({ show: false, message: "" });
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions.length > 0) {
      setProgress(Math.round((100 / questions.length) * (currentQuestionIndex + 1)));
    }
  }, [currentQuestionIndex, questions.length]);
  

  const loadQuizQuestions = async (type) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/quiz/${type}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setQuestions(response.data);
      setQuizType(type);
      setCurrentQuestionIndex(0);
    } catch (error) {
      console.error("Error fetching quiz questions:", error);
    }
  };

  const handleAnswerSelection = (option, points, followUp, skipFollowUp) => {
    const newAnswer = {
      questionId: questions[currentQuestionIndex]._id,
      selectedOption: option,
    };
    setTotalPoints(totalPoints + points);

    let nextIndex = currentQuestionIndex + 1;
    if (
      option === questions[currentQuestionIndex].options[0].text &&
      skipFollowUp !== undefined
    ) {
      nextIndex = skipFollowUp;
    } else if (followUp !== undefined) {
      nextIndex = followUp;
    }

    setSelectedAnswers([...selectedAnswers, newAnswer]);
    setSelectedOption("");
    setCurrentQuestionIndex(nextIndex);
    return newAnswer;
  };

  const submitQuizResults = async (finalAnswers) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/quiz/submit`,
        { quizType, quizAnswers: finalAnswers, quizPoints: totalPoints },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting quiz results:", error);
    }
  };

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block lg:px-[180px] pb-[25px] md:pb-[45px] lg:pb-[65px]">
        <div className="pb-[10px]">
            <img src={Logo} alt='' className="m-auto site-logo"/>
            <h1 className="text-lg md:text-3xl lg:text-5xl my-[15px] font-extra-bold-header">Calculate Your Carbon Footprint</h1>
          {!quizType && (
            <div className="flex justify-between max-w-[600px] m-auto py-[25px] md:py-[40px] lg:py-[50px]">
              <Button handleOnClick={() => loadQuizQuestions("individual")} className="w-[240px]" buttonText={'Take Individual Quiz'}/>
              <Button handleOnClick={() => loadQuizQuestions("corporate")} className="w-[240px] border-secondary-200 hover:text-secondary-200 bg-secondary-200" buttonText={'Take Corporate Quiz'}/>
            </div>
          )}

          {quizType && currentQuestionIndex < questions.length && (
            <div className="">
              <ProgressBar progress={progress}/>
            <h2 className="secondary-header py-[20px] md:py-[27px] lg:py-[40px]">Question {currentQuestionIndex + 1}: {questions[currentQuestionIndex].text}</h2>
              {questions[currentQuestionIndex].options.map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer w-full form-fields text-left mb-[15px] ${
                    selectedOption === option.text ? "bg-primary text-white" : "bg-transparent"
                  }`}
                  onClick={() => setSelectedOption(option.text)}
                >
                  <input
                    type="radio"
                    name="quizOption"
                    value={option.text}
                    checked={selectedOption === option.text}
                    onChange={() => {}} 
                    className="hidden"
                  />
                  <span className="ml-2 text-lg">{option.text}</span>
                </div>
              ))}
              <Button 
              handleOnClick={() => {
                if (selectedOption) {
                  const selected = questions[currentQuestionIndex].options.find(
                    (o) => o.text === selectedOption
                  );

                  if (currentQuestionIndex === questions.length - 1) {
                    const newAnswer = handleAnswerSelection(
                      selected.text,
                      selected.points,
                      questions[currentQuestionIndex].followUp,
                      questions[currentQuestionIndex].skipFollowUp
                    );
                    submitQuizResults([...selectedAnswers, newAnswer]);
                  } else {
                    const newAnswer = handleAnswerSelection(
                      selected.text,
                      selected.points,
                      questions[currentQuestionIndex].followUp,
                      questions[currentQuestionIndex].skipFollowUp
                    );
                  }
                } else {
                  
                  setPopup({ show: true, message: "Please select an answer" });
                  setTimeout(() => setPopup({ show: false, message: "" }), 1000);
                }
              }} className="lg:w-[240px] mt-[10px] lg:mt-[20px]" buttonText={currentQuestionIndex < questions.length - 1
                ? "Next"
                : "Submit Quiz"}/>
            </div>
          )}

          {quizType && currentQuestionIndex >= questions.length && (
            <div>Quiz Completed. Redirecting...</div>
          )}
          </div>
      </div>
      {popup.show && (
          <Popup message={popup.message}/>
        )}
    </div>
  );
};

export default QuizComponent;
