import React, { useState, useEffect } from "react";
import plans from "../plans/PlanData";
import Button from "../uiKit/Button";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Loader from "../uiKit/Loader";
import Popup from "../uiKit/Popup";

const MembershipPlan = ({ showTitle = "false" }) => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("isCancelled");
  const [showLoader, setShowLoader] = useState(false);
  const [popup, setPopup] = useState({ show: false, message: "" });

  useEffect(() => {
    // Check if userData is empty and fetch data only if needed
    if (Object.keys(userData).length === 0) {
      fetchUserData();
    } else {
      if (userData.subscriptionStatus !== "cancelled") {
        setSelectedPlan(userData.membershipType);
      }
    }
  }, [userData.membershipType, userData.subscriptionStatus]);

  const fetchUserData = async () => {
    try {
      // setLoading(true);
      setShowLoader(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      // console.error("Error fetching user data:", error);
      // setError(error.message);
      setPopup({ show: true, message: "Error fetching user data:", error });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    } finally {
      // setLoading(false);
      setShowLoader(false);
    }
  };

  /*
  useEffect(() => {
    fetchUserData();
  }, []);
*/

  const handleSubscriptionChange = async (plan) => {
    const newPlan = plan.title;
    try {
      setShowLoader(true);
      const token = localStorage.getItem("token");

      let response;
      if (userData.paymentMethod === "paypal") {
        // If the user's payment method is PayPal
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/change-paypal-subscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ newPlan }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to change PayPal subscription");
        }
        const data = await response.json();
        window.location.href = data.approvalUrl; // Redirect to PayPal
      } else {
        // If the user's payment method is Stripe or others
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/change-stripe-subscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ newPlan }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to change subscription");
        }
        setUserData({ ...userData, membershipType: newPlan });
      }
      fetchUserData();
      setShowLoader(false);
    } catch (err) {
      // setError(err.message || "Failed to change subscription");
      setPopup({
        show: true,
        message: err.message || "Failed to change subscription",
      });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      setShowLoader(false);
    }
  };
  /*
  const renderButton = (plan) => {
    if (loading) return <button disabled>Loading...</button>;

    const isCancelled = userData.subscriptionStatus === "cancelled";
    const isCurrentPlanSelected =
      userData.membershipType === plan && !isCancelled;
    return (
      // <button
      //   onClick={() => handleSubscriptionChange(plan)}
      //   disabled={isCurrentPlanSelected}
      // >
      //   {isCurrentPlanSelected
      //     ? "Selected"
      //     : isCancelled || userData.membershipType < plan
      //     ? "Upgrade"
      //     : "Downgrade"}
      // </button>
      <Button
        type="submit"
        onClick={() => handleSubscriptionChange(plan)}
        disabled={isCurrentPlanSelected}
        className="hover:border-[2px] py-[10px] text-[18px]"
        buttonText={
          isCurrentPlanSelected
            ? "Selected"
            : isCancelled || userData.membershipType < plan
            ? "Upgrade"
            : "Downgrade"
        }
      />
    );
  };

  */

  const renderButton = (plan) => {
    if (showLoader) return <button disabled>Loading...</button>;

    const isCancelled = userData.subscriptionStatus === "cancelled";
    const isCurrentPlanSelected =
      userData.membershipType === plan.title && !isCancelled;

    return (
      <Button
        handleOnClick={() => handleSubscriptionChange(plan)}
        handleDisable={isCurrentPlanSelected}
        className="hover:border-[2px] py-[10px] text-[18px]"
        buttonText={
          isCurrentPlanSelected
            ? "Selected"
            : isCancelled || userData.membershipType < plan.title
            ? "Upgrade"
            : "Downgrade"
        }
      />
    );
  };

  if (error) return <div>Error: {error}</div>;
  if (!userData) return <div>Loading user data...</div>;

  return (
    <>
      <div className="px-[10px] text-lg font-bold-header pt-[20px] pb-[20px] md:pb-[30px] lg:pb-[45px] leading-[30px]">
        {showTitle &&
          (selectedPlan === "isCancelled" ? (
            <>You don't currently have any subscription</>
          ) : (
            <>
              You are currently on the{" "}
              <span className="text-[#146704]">{selectedPlan} package</span>
            </>
          ))}
      </div>
      <div className="membership-container flex flex-col md:flex-row text-center">
        {plans.map((plan) => (
          <div
            key={plan.title}
            className={twMerge(
              "subscription-card basis-1/3 m-[10px]",
              plan.background
            )}
          >
            <div className="border-b-[2px] border-white/50 pb-[9px] px-[10px] py-[5px] pt-[12px] md:pt-[20px]">
              <h4 className="text-white font-semibold text-[19px] md:text-[21px] lg:text-[23px] pb-[16px] capitalize">
                {plan.title}
              </h4>
              <h3 className="text-white font-semibold text-[21px] md:text-[26px] lg:text-[30px]">
                {plan.price}
              </h3>
            </div>
            <div className="border-b-[1px] border-white/50 py-[12px] md:py-[20px] lg:py-[28px] px-[10px]">
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index} className="my-[2px]">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-white pr-[16px] text-[24px]"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            {renderButton(plan)}
          </div>
        ))}
        {showLoader && <Loader />}
        {popup.show && <Popup message={popup.message} />}
      </div>
    </>
  );
};

export default MembershipPlan;
