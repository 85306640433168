import React from 'react'

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <div>
        <ul className='flex justify-center mt-[20px] pt-[20px] border-t-[2px] border-[#e5e7eb]'>
          {pageNumbers.map(number => (
            <li key={number} className='mx-[8px]'>
              <button onClick={() => paginate(number)} className={`px-[16px] py-[8px] border rounded ${currentPage === number ? 'bg-primary text-white' : 'bg-white'}`}>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  

export default Pagination