import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../uiKit/Button";

const PaypalInvoiceComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const invoiceDetails = location.state?.invoiceDetails;

  if (!invoiceDetails) {
    return <p>No invoice details available.</p>;
  }

  
  return (
    <div className="container-dashboard lg-basis-100%]">
      <div className="max-w-[900px] m-auto py-[20px] md:py-[40px] lg:py-[60px] px-[20px]">
      <div className="admin-page-header text-center pb-[20px] md:pb-[30px] lg:pb-[40px]">Paypal Payment Detailed Invoice</div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Invoice Id</label>
            <input
              type="text"
              value={invoiceDetails.invoiceId}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          <div className="profile-details-fields">
            <label className="form-label">Invoice Date</label>
            <input
              type="text"
              value={invoiceDetails.invoiceDate}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Full Name</label>
            <input
              type="text"
              value={invoiceDetails.fullName}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          <div className="profile-details-fields">
            <label className="form-label">Email</label>
            <input
              type="text"
              value={invoiceDetails.email}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Subscription Start Date</label>
            <input
              type="text"
              value={invoiceDetails.subscriptionStartDate}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          <div className="profile-details-fields">
            <label className="form-label">Subscription End Date</label>
            <input
              type="text"
              value={invoiceDetails.subscriptionEndDate}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Currency</label>
            <input
              type="text"
              value={invoiceDetails.currency}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          <div className="profile-details-fields">
            <label className="form-label">Plan ID</label>
            <input
              type="text"
              value={invoiceDetails.planId}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Plan Name</label>
            <input
              type="text"
              value={invoiceDetails.planName}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          <div className="profile-details-fields">
            <label className="form-label">Quantity</label>
            <input
              type="text"
              value={invoiceDetails.quantity}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields">
            <label className="form-label">Amount Paid</label>
            <input
              type="text"
              value={invoiceDetails.amountPaid}
              className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
              readOnly
            />
          </div>
          {invoiceDetails.nextBillingDate !== "Invalid Date" && (
            <div className="profile-details-fields">
              <label className="form-label">Next Billing Date</label>
              <input
                type="text"
                value="N/A"
                className="form-fields w-full bg-[#f0f0f0] focus:outline-none"
                readOnly
              />
            </div>
          )}
        </div>
        <div className="register-field-container">
          <div className="profile-details-fields w-full text-center">
            <Button handleOnClick={() => navigate("/payment-history")} className="lg:w-[240px] mt-[20px] lg:mt-[30px]" buttonText={'Back to Payment History'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalInvoiceComponent;
