import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import ProjectInsightsComponent from '../../components/user/ProjectInsightsComponent'
import Footer from '../../components/uiKit/Footer'

const UserDashboard = () => {
  return (
    <>
        <TopBar/>
        <ProjectInsightsComponent/>
        <Footer/>
    </>
  )
}

export default UserDashboard