import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../pictures/logo.png';
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Loader from "../uiKit/Loader";

const AdminLoginComponent = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showLoader, setShowLoader] = useState(false);


  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChangeAndBlur = (e) => {
      handleChange(e);
      handleBlur(e);
  };

  const validateField = (name, value) => {
    if (name === 'email') {
      if (!value) {
        return 'Email is required';
      } else if (!/^\S+@\S+\.\S+$/.test(value)) {
        return 'Invalid email address';
      }
    } else if (name === 'password') {
      if (!value) {
        return 'Password is required';
      } else if (value.length < 8) {
        return 'Password must be at least 8 characters long';
      } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(value)) {
        return 'Password must contain at least one capital letter and one number';
      }
    }
    return '';
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prevTouched => ({ ...prevTouched, [name]: true }));
    validateField(name, value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);

    
    const validationErrors = Object.keys(loginData).reduce((acc, field) => {
      acc[field] = validateField(field, loginData[field]);
      setShowLoader(false);
      return acc;
    }, {});

    if (Object.values(validationErrors).some(error => error)) {
      setShowLoader(false);
      // setPopup({ show: true, message: "Please correct the errors before submitting." });
      // setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      return;
    }


    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(loginData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("adminToken", data.token);
        setShowLoader(false)
        navigate("/admin/profile");
      } else {
        // alert(data.message);
        setShowLoader(false)
        setPopup({ show: true, message: data.message });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      }
    } catch (error) {
      // console.error("Login failed:", error);
      setShowLoader(false)
      setPopup({ show: true, message: "Login failed:" + error });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
    }
  };

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block max-w-[550px]">
        <div className="max-w-[350px] m-auto pb-[10px]">
          <img src={Logo} alt='' className="m-auto site-logo"/>
          <h1 className="text-lg md:text-3xl lg:text-5xl my-[10px] lg:my-[15px] font-bold-header">Welcome Admin!</h1>
          <p>Login as admin to Fix My Planet account.</p>
        </div>
        <form onSubmit={handleSubmit} className="text-left">
          <div className="form-group">
            <div><label className="form-label" htmlFor="email">Email</label></div>
            <div>
              <input
                type="email"
                name="email"
                value={loginData.email}
                // onChange={handleChange}
                // onBlur={handleBlur}
                onChange={handleChangeAndBlur}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter your email"
                required
              />
              {touched.email && errors.email && <p className="form-error">{errors.email}</p>}
            </div>
          </div>
          <div className="form-group">
            <div><label className="form-label" htmlFor="password">Password</label></div>
            <div>
              <input
                type="password"
                name="password"
                value={loginData.password}
                // onChange={handleChange}
                // onBlur={handleBlur}
                onChange={handleChangeAndBlur}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter your password"
                required
              />
              {touched.password && errors.password && <p className="form-error">{errors.password}</p>}
            </div>
          </div>
          <Button type="submit" className="my-[5px]" buttonText={'Login'}/>
        </form>
      </div>
      {popup.show && (
        <Popup message={popup.message}/>
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default AdminLoginComponent;