import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import AdminProfileComponent from '../../components/admin/AdminProfileComponent'
import Footer from '../../components/uiKit/Footer'

const AdminLogin = () => {
  return (
    <>
        <TopBar/>
        <AdminProfileComponent/>
        <Footer/>
    </>
  )
}

export default AdminLogin