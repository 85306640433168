import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Logo from '../../pictures/logo.png'
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";

const ResetPasswordComponent = () => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { token } = useParams();
  const [popup, setPopup] = useState({ show: false, message: "" });
  const navigate = useNavigate();

  const validatePassword = (password) => {
    if (!password) {
      return 'Password is required';
    } else if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(password)) {
      return 'Password must contain at least one capital letter and one number';
    }
    return '';
  };

  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
    const error = validatePassword(newPass);
    setPasswordError(error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const error = validatePassword(password);
    if (error) {
      setPasswordError(error);
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, password }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPopup({ show: true, message: "Password reset successfully" });
        setTimeout(() => {
          setPopup({ show: false, message: "" });
          navigate("/login");
        }, 2000);        
      })
      .catch((error) => {
        console.error("Error:", error);
        setPopup({ show: true, message: "Failed to reset password: " + error.message});
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      });
  };

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block max-w-[550px]">
        <div className="max-w-[350px] m-auto pb-[10px]">
          <img src={Logo} alt='' className="m-auto site-logo"/>
          <h1 className="text-lg md:text-3xl lg:text-5xl my-[10px] lg:my-[15px] font-bold-header">Reset Password</h1>
          <p>Enter your new password</p>
        </div>
        <form onSubmit={handleSubmit} className="text-left">
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter new password"
                required
              />
              {passwordError && <p className="form-error">{passwordError}</p>}
            </div>
          </div>
          <Button type="submit" className="my-[5px]" buttonText={'Reset Password'}/>
        </form>
      </div>
      {popup.show && (
        <Popup message={popup.message}/>
      )}
    </div>
  );
};

export default ResetPasswordComponent;
