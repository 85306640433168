import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import QuizComponent from '../../components/user/QuizComponent'

const Quiz = () => {
  return (
    <>
        <TopBar/>
        <QuizComponent/>
    </>
  )
}

export default Quiz