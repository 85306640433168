import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import MembershipComponent from '../../components/user/MembershipComponent'

const Membership = () => {
  return (
    <>
        <TopBar/>
        <MembershipComponent/>
    </>
  )
}

export default Membership