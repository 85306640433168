import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import UserLoginComponent from '../../components/user/UserLoginComponent'

const UserLogin = () => {
  return (
    <>
        <TopBar/>
        <UserLoginComponent/>
    </>
  )
}

export default UserLogin