// src/components/user/PaypalPaymentForm.js
import React, { useEffect, useState } from "react";

const PaypalPaymentForm = ({
  formData,
  onPaymentSuccess,
  onPaymentError,
  onPaymentCancel,
}) => {
  const [isPaypalReady, setIsPaypalReady] = useState(false);

  useEffect(() => {
    if (!window.paypal) {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
      script.onload = () => setIsPaypalReady(true);
      document.body.appendChild(script);
    } else {
      setIsPaypalReady(true);
    }
  }, []);

  useEffect(() => {
    if (isPaypalReady) {
      const container = document.getElementById("paypal-button-container");
      container.innerHTML = ""; // Clear previous PayPal buttons

      const planId = getPayPalPlanId(formData.membershipType);

      window.paypal
        .Buttons({
          createSubscription: (data, actions) => {
            return actions.subscription.create({ plan_id: planId });
          },
          onApprove: async (data) => {
            try {
              //get subscription details from my paypal controller
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/users/paypal-subscription/${data.subscriptionID}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              const subscriptionDetails = await response.json();
              if (!response.ok) {
                throw new Error(
                  subscriptionDetails.message || "Failed to get subscription."
                );
              }

              const invoice = {
                paymentDate: subscriptionDetails.billing_info.last_payment.time,
                details: formData.membershipType,
                amount:
                  subscriptionDetails.billing_info.last_payment.amount.value,
                invoiceUrl: subscriptionDetails.links[2].href,
              };

              onPaymentSuccess(data.subscriptionID, invoice);
            } catch (error) {
              onPaymentError(error);
            }
          },
          onError: (err) => {
            console.error("PayPal Payment Error:", err);
            onPaymentError(err);
          },
          onCancel: () => {},
        })
        .render("#paypal-button-container");
    }
  }, [isPaypalReady, formData.membershipType]);

  const getPayPalPlanId = (membershipType) => {
    switch (membershipType) {
      case "type1":
        return process.env.REACT_APP_PAYPAL_PLAN_ID_TYPE1;
      case "type2":
        return process.env.REACT_APP_PAYPAL_PLAN_ID_TYPE2;
      case "type3":
        return process.env.REACT_APP_PAYPAL_PLAN_ID_TYPE3;
      default:
        return null;
    }
  };

  return <div id="paypal-button-container"></div>;
};

export default PaypalPaymentForm;
