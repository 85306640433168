import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminEditProject from "./AdminEditProject";
import AdminSideBar from '../uiKit/AdminSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import Popup from "../uiKit/Popup";
import Modal from "../uiKit/Modal";
import Pagination from "../uiKit/Pagination";

const AdminProjectListComponent = () => {
  const [projects, setProjects] = useState([]);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);
  const navigate = useNavigate();

  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [fieldToConfirm, setFieldToConfirm] = useState("");




  // State for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Can be adjusted as needed

  // Function to change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Calculate the currently displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);
  const shouldDisplayPagination = projects.length > itemsPerPage;




  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate("/admin/login");
    }

    fetchProjects();
  }, [navigate]);

  const fetchProjects = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/projects`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }

      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = (projectId, projectName) => {
    setFieldToConfirm(projectName);
    setProjectIdToDelete(projectId)
    setShowConfirmPopup(true); 
  };

  const handleConfirmDelete = async (projectId) => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete project");
      }

      // Update projects list after deletion
      fetchProjects();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = (projectId) => {
    setEditingProjectId(projectId);
  };

  return (
    <div>
      <PageTitle title={"Admin Access"} />
        <div className='flex'>
          <AdminSideBar/>
          <div className="admin-container-dashboard pt-[20px]">
            <div className="md:px-[9px] lg:px-[13px]">
              <div className="admin-page-header md:px-[0px] lg:px-[0px]">Project List</div>
                <div className="flex border-b-[2px] border-[#3d3d3d] pb-[20px] mb-[20px] font-semibold">
                  <div className="basis-[30%] min-w-[70px] max-w-[30%]">Name</div>
                  <div className="basis-[32%] min-w-[70px] max-w-[32%]">Start Date</div>
                  <div className="basis-[19%] min-w-[50px] max-w-[19%]">Edit</div>
                  <div className="basis-[19%] min-w-[50px] max-w-[19%]">Delete</div>
                </div>
                {currentItems.map((project) => (
                  <div key={project._id} className="flex py-[4px]">
                    <div className="basis-[30%] min-w-[70px] max-w-[30%]">{project.name}</div>
                    <div className="basis-[32%] min-w-[70px] max-w-[32%]">{new Date(project.startDate).toISOString().split('T')[0]}</div>
                    <div className="basis-[19%] min-w-[50px] max-w-[19%]">
                      <FontAwesomeIcon icon={faPenToSquare} onClick={() => handleEdit(project._id)}/>
                    </div>
                    <div className="basis-[19%] min-w-[50px] max-w-[19%]">
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(project._id, project.name)} className="text-error"/>
                    </div>
                  </div>
                ))}
                {shouldDisplayPagination && (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={projects.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                )}
            </div>
          </div>
        </div>
        {editingProjectId && (
          <AdminEditProject
            projectId={editingProjectId}
            onUpdate={() => {
              setEditingProjectId(null);
              fetchProjects();
            }}
            onCancel={() => setEditingProjectId(null)}
          />
        )}
        {popup.show && (
          <Popup message={popup.message}/>
        )}
        {showConfirmPopup && (
          <Modal 
            fieldName={fieldToConfirm}
            onConfirm={() => {
              handleConfirmDelete(projectIdToDelete);
              setProjectIdToDelete(null)
              setShowConfirmPopup(false);
              setPopup({ show: true, message: "Project has been deleted" });
              setTimeout(() => setPopup({ show: false, message: "" }), 2000);
            }}
            onCancel={() => setShowConfirmPopup(false)}
            message={`Are you sure you want to delete ${fieldToConfirm}?`}
          />
        )}
      </div>
  );
};

export default AdminProjectListComponent;
