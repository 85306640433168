import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import UserMembershipComponent from '../../components/user/UserMembershipComponent'
import Footer from '../../components/uiKit/Footer'

const UserMembership = () => {
  return (
    <>
        <TopBar/>
        <UserMembershipComponent/>
        <Footer/>
    </>
  )
}

export default UserMembership