import React from 'react';
import { twMerge } from 'tailwind-merge';

const Button = ({ handleOnClick, className, buttonText, handleDisable }) => {
  const mergedClassNames = twMerge(
    'w-full bg-primary py-[12px] transition-300 border-[2px] border-primary text-white hover:bg-white hover:text-primary',
    className
  );

  return (
    <button
        onClick={handleOnClick}
        disabled={handleDisable}
        className={mergedClassNames}
    >
        {buttonText}
    </button>
  );
};

export default Button;
