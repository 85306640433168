import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import PaypalInvoiceComponent from '../../components/user/PaypalInvoiceComponent'

const UserLogin = () => {
  return (
    <>
        <TopBar/>
        <PaypalInvoiceComponent/>
    </>
  )
}

export default UserLogin