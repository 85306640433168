import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import CancelMembershipComponent from '../../components/user/CancelMembershipComponent'
import Footer from '../../components/uiKit/Footer'

const CancelMembership = () => {
  return (
    <>
        <TopBar/>
        <CancelMembershipComponent/>
        <Footer/>
    </>
  )
}

export default CancelMembership