import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../pictures/logo.png";
import Button from "../uiKit/Button";
import Popup from "../uiKit/Popup";
import Loader from "../uiKit/Loader";

const UserLoginComponent = () => {
  const navigate = useNavigate();

  const [popup, setPopup] = useState({ show: false, message: "" });
  const [showLoader, setShowLoader] = useState(false);


  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChangeAndBlur = (e) => {
    handleChange(e);
    handleBlur(e);
};
  
  const validateField = (name, value) => {
    if (name === 'email') {
      if (!value) {
        return 'Email is required';
      } else if (!/^\S+@\S+\.\S+$/.test(value)) {
        return 'Invalid email address';
      }
    } else if (name === 'password') {
      if (!value) {
        return 'Password is required';
      } else if (value.length < 8) {
        return 'Password must be at least 8 characters long';
      } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(value)) {
        return 'Password must contain at least one capital letter and one number';
      }
    }
    return '';
  };
    
  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prevTouched => ({ ...prevTouched, [name]: true }));
    validateField(name, value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };




  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard"); 
    }
  }, [navigate]);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);

    const validationErrors = Object.keys(loginData).reduce((acc, field) => {
      acc[field] = validateField(field, loginData[field]);
      setShowLoader(false);
      return acc;
    }, {});

    if (Object.values(validationErrors).some(error => error)) {
      setShowLoader(false);
      // setPopup({ show: true, message: "Please correct the errors before submitting." });
      // setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      return;
    }

    if (!loginData.email || !loginData.password) {
      // alert("Please enter both email and password");
      setShowLoader(false);
      setPopup({ show: true, message: "Please enter both email and password" });
      setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => {
        if (!response.ok) {
          setShowLoader(false);
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("token", data.token);
        setShowLoader(false);
        navigate("/dashboard"); // Directly navigate to dashboard
      })
      .catch((error) => {
        console.error("Error:", error);
        // alert("Login failed: " + error.message);
        setShowLoader(false);
        setPopup({ show: true, message: "Login failed: " + error.message });
        setTimeout(() => setPopup({ show: false, message: "" }), 2000);
      });
  };

  return (
    <div className="container-form">
      <div className="transparent-bg inline-block max-w-[550px]">
        <div className="max-w-[350px] m-auto pb-[10px]">
          <img src={Logo} alt="" className="m-auto site-logo" />
          <h1 className="text-lg md:text-3xl lg:text-5xl my-[10px] lg:my-[15px] font-bold-header">
            Welcome Back!
          </h1>
          <p>Login to continue to Fix My Planet account.</p>
        </div>
        <form onSubmit={handleSubmit} className="text-left">
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="email">
                Email
              </label>
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={loginData.email}
                // onBlur={handleBlur}
                // onChange={handleChange}
                onChange={handleChangeAndBlur}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter your email"
                required
              />
            </div>
          </div>
          {touched.email && errors.email && <p className="form-error">{errors.email}</p>}
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="password">
                Password
              </label>
            </div>
            <div>
              <input
                type="password"
                name="password"
                value={loginData.password}
                // onBlur={handleBlur}
                // onChange={handleChange}
                onChange={handleChangeAndBlur}
                className="w-full form-fields my-[9px] md:my-[12px] lg:mt-[13px] lg:mb-[17px]"
                placeholder="Enter your password"
                required
              />
            </div>
          </div>
          {touched.password && errors.password && <p className="form-error">{errors.password}</p>}
          <Button type="submit" className="my-[5px]" buttonText={"Login"} />
        </form>
        <div className="login-links text-left">
          <div>
            <a href="/forgot-password" className="hover:text-primary">
              Forgot Password?
            </a>
          </div>
          <div>
            <p className="inline">Don't have an account? </p>
            <a href="/register" className="text-primary">
              Get Started
            </a>
          </div>
        </div>
      </div>
      {popup.show && <Popup message={popup.message} />}
      {showLoader && <Loader />}
    </div>
  );
};

export default UserLoginComponent;
