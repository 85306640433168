import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import ForgotPasswordComponent from '../../components/user/ForgotPasswordComponent'

const ForgotPassword = () => {
  return (
    <>
        <TopBar/>
        <ForgotPasswordComponent/>
    </>
  )
}

export default ForgotPassword