import React from 'react'
import TopBar from '../../components/uiKit/TopBar'
import AdminLoginComponent from '../../components/admin/AdminLoginComponent'

const AdminLogin = () => {
  return (
    <>
        <TopBar/>
        <AdminLoginComponent/>
    </>
  )
}

export default AdminLogin