import React, { useEffect, useState } from "react";
import UserSideBar from '../../components/uiKit/UserSideBar'
import PageTitle from '../../components/uiKit/PageTitle'
import { useNavigate } from "react-router-dom";
import Button from "../uiKit/Button";
import treeImage from "../../pictures/tree.png";

const UserDashboardComponent = () => {
  const [userData, setUserData] = useState({});
  const [carbonPrice, setCarbonPrice] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
    fetchCarbonPrice();

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserData = () => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUserData(data))
      .catch((error) => console.error("Error fetching user data:", error));
  };

  const fetchCarbonPrice = () => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/api/users/carbon-price`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setCarbonPrice(data.price))
      .catch((error) => console.error("Error fetching carbon price:", error));
  };

  const handleTakeQuiz = () => {
    if (!userData.hasTakenQuiz) {
      navigate("/take-quiz");
    }
  };

  const generateTrees = (count) => {
    const treeArray = [];
    const containerWidth = 100; // Percentage of container width
    const containerHeight = windowWidth >= 768 ? 60 : 42; // Reduced height for mobile
    const maxTreeSize = 6; // Maximum tree size in vh units
    const minTreeSize = 4; // Minimum tree size in vh units

    let limitedCount;
    if (windowWidth >= 1024) { // PC
      limitedCount = Math.min(count, 70);
    } else if (windowWidth >= 768) { // Tablet
      limitedCount = Math.min(count, 40);
    } else { // Mobile
      limitedCount = Math.min(count, 15);
    }

    for (let i = 0; i < limitedCount; i++) {
      const left = Math.random() * (containerWidth - maxTreeSize);
      const bottom = Math.random() * containerHeight;
      const size = minTreeSize + Math.random() * (maxTreeSize - minTreeSize);
      treeArray.push(
        <img
          key={i}
          src={treeImage}
          alt="Tree"
          className="absolute"
          style={{
            left: `${left}%`,
            bottom: `${bottom}%`,
            width: `${size}vh`,
            height: `${size}vh`,
            transition: 'all 0.5s ease-in-out',
          }}
        />
      );
    }
    return treeArray;
  };

  return (
    <div>
      <PageTitle title={`Welcome, ${userData.firstName}!`} />
      <div className='flex'>
        <UserSideBar/>
        <div className="container-dashboard bg-image-dashboard relative">
          <div className="flex mx-[20px] md:mx-[50px] lg:mx-[90px] pb-[25px] mb-[25px] md:pb-[30px] md:mb-[40px] lg:pb-[40px] lg:mb-[50px] flex-col md:flex-row justify-center border-b-[2px] border-[#a1a29a]">
            <div className="border-[2px] border-[#626262] rounded-[45px] px-[70px] py-[25px] text-center m-[10px] bg-white">
              <div className="text-accent-100 text-xl md:text-4xl lg:text-6xl font-semibold md:font-extrabold pb-[10px] md:pb-[15px] lg:pb-[20px]">{userData.treesPlanted}</div>
              <p>Virtual Trees You've Planted</p>
            </div>
            <div className="border-[2px] border-[#626262] rounded-[45px] px-[70px] py-[25px] text-center m-[10px] bg-white">
              <div className="text-accent-100 text-xl md:text-4xl lg:text-6xl font-semibold md:font-extrabold pb-[10px] md:pb-[15px] lg:pb-[20px]">${carbonPrice}</div>
              <p>Live Carbon Price Today</p>
            </div>
          </div>
          <div className="flex">
            {!userData.hasTakenQuiz && (
              <Button handleOnClick={handleTakeQuiz} className="w-[240px] m-auto" buttonText={'Take Quiz'}/>
            )}
          </div>
          <div className="absolute bottom-0 left-0 right-0 w-full h-[30vh] overflow-hidden">
            {generateTrees(userData.treesPlanted)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardComponent;